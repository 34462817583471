import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "ellipsis",
    class: _normalizeClass(["tm-ellipsis", {
      'tm-ellipsis--inline': _ctx.inline,
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["tm-ellipsis__in", _ctx.ellipsisInClass])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 2))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78a6110a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chunks, (chunk, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: 'highlight' + index,
        class: _normalizeClass({ highlight: chunk.isHighlighted }),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        innerHTML: chunk.text
      }, null, 10, _hoisted_1))
    }), 128))
  ]))
}

import type { PropType } from 'vue'
import { defineComponent, toRefs, computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'
import type { IconSize, SizeProp, IconRotate } from '@/definitions/shared/types'

export default defineComponent({
  name: 'TmDropdownItem',
  components: {
    TmSkeleton,
  },
  props: {
    stopPropagation: {
      type: Boolean,
    },
    additionalText: {
      type: String,
    },
    additionalTextClass: {
      type: String,
      default: 'distinct--text ml-1',
    },
    cursorDefault: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    noHover: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    focused: {
      type: Boolean,
    },
    focusedColor: {
      type: String as PropType<'shade' | 'lift' | 'input'>,
      default: 'input',
    },
    iconMirror: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
    },
    iconRotate: {
      type: Number as PropType<IconRotate>,
    },
    iconClass: {
      type: String,
    },
    textColor: {
      type: String as PropType<'red' | 'blue' | ''>,
      default: '',
    },
    borderBottom: {
      type: Boolean,
    },
    borderTop: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    labelClass: {
      type: String,
      default: '',
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
    href: {
      type: String,
    },
    headerStyle: {
      type: Boolean,
    },
    outside: {
      type: Boolean,
    },
  },
  setup(props) {
    const {
      stopPropagation,
      href,
      to,
    } = toRefs(props)

    const clickHandler = (e: Event) => {
      if (stopPropagation.value) e.stopPropagation()
    }

    const componentType = computed(() => {
      if (href.value) return 'a'
      if (to.value) return 'router-link'
      return 'button'
    })

    const componentBind = computed(() => {
      if (href.value) return { href: href.value, target: '_blank' }
      if (to.value) return { to: to.value }
      return { type: 'button' }
    })

    return {
      clickHandler,
      componentType,
      componentBind,
    }
  },
})

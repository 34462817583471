import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-137809b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "truncate mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_chip = _resolveComponent("tm-chip")!

  return (_openBlock(), _createBlock(_component_tm_chip, {
    class: "tm-chip-person",
    outline: "",
    close: "",
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
    onClickClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_avatar, {
        url: _ctx.avatarUrl,
        name: _ctx.label,
        color: _ctx.avatarColor,
        "custom-icon-class": "mx-0",
        size: "small",
        class: "tm-chip-person__avatar ml-n5px"
      }, null, 8, ["url", "name", "color"]),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.capitalize(_ctx.label)), 1)
    ]),
    _: 1
  }))
}
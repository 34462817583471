
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmChip from '@/components/shared/TmChip.vue'
import { capitalize } from '@/services/utils'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import type { AvatarColors } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmAvatar,
    TmChip,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    avatarUrl: {
      type: String,
    },
    avatarColor: {
      type: String as PropType<AvatarColors>,
      default: 'green',
    },
  },
  emits: ['click-close'],
  setup() {
    return {
      capitalize,
    }
  },
})

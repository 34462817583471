
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import type { SizeProp, FlagSize, FlagCountry } from '@/definitions/shared/types'

export default defineComponent({
  name: 'TmCountry',
  components: { TmFlag },
  props: {
    hideTitle: {
      type: Boolean,
    },
    country: {
      type: Object as PropType<FlagCountry>,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    customFlagPath: {
      type: String,
    },
    size: {
      type: String as SizeProp<FlagSize>,
      default: '',
    },
  },
  setup(props) {
    const customFlag = computed(() => {
      if (!props.customFlagPath) return ''
      return require(`@/assets/${props.customFlagPath}`)
    })

    return {
      customFlag,
    }
  },
})


import type { PropType } from 'vue'
import { computed, defineComponent, nextTick, ref, watch } from 'vue'
import type { TooltipPosition } from '@/compositions/renderTooltip'
import { renderTooltip } from '@/compositions/renderTooltip'
import TmButton from '@/components/shared/TmButton.vue'
import type { SizeProp } from '@/definitions/shared/types'
import { dropdownClassName } from '@/definitions/_general/_data/general'
import TmTooltipDropdown from '@/components/shared/TmTooltipDropdown.vue'

export default defineComponent({
  name: 'TmDropdown',
  components: { TmTooltipDropdown, TmButton },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    closeOnContentClick: {
      type: Boolean,
      default: true,
    },
    filterDropdown: {
      type: Boolean,
      default: false,
    },
    overflowHidden: {
      type: Boolean,
      default: false,
    },
    visibleScrollbar: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<'' | 'small'>,
      default: '',
    },
    maxHeightUnset: {
      type: Boolean,
    },
    minWidth: {
      type: String,
    },
    contentClass: {
      type: String,
      default: '',
    },
    showClose: {
      type: Boolean,
    },
    target: {
      type: HTMLElement,
    },
    offset: {
      type: Array as PropType<number[]>,
    },
    offsetWithAngle: {
      type: Boolean,
    },
    position: {
      type: String as PropType<TooltipPosition>,
      default: 'bottom-right',
    },
    hideDropdownWrapper: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    borderRadius: {
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
  },
  emits: ['update:modelValue', 'show', 'hide'],
  setup(props, context: any) {
    const qMenu = ref()
    const wrapRef = ref()
    const innerMenuRef = ref()
    const internalValue = ref(props.modelValue)
    const { getAngleAttrs, show, hide } = renderTooltip()
    const angleAttrs = getAngleAttrs('tm-dropdown', props.position, props.offsetWithAngle)

    const autoClose = ref(
      props.filterDropdown
        ? false
        : props.closeOnContentClick
    )

    // create reactive attributes without class
    const attrsWithoutClass = computed(() => {
      const clonedAttrs = Object.assign({}, context.attrs)
      delete clonedAttrs.class
      return clonedAttrs
    })

    const isMinWidth = computed(() => props.minWidth ? { minWidth: props.minWidth } : {})

    const attrs = computed(() => ({
      ...angleAttrs,
      ...attrsWithoutClass.value,
      offset: props.offset || angleAttrs.offset,
    }))

    watch(
      () => props.modelValue,
      (newValue) => {
        internalValue.value = newValue
      }
    )

    watch(
      () => internalValue.value,
      (newValue) => {
        context.emit('update:modelValue', newValue)
      }
    )

    const beforeShow = async () => {
      const anchorEl = props.target || wrapRef.value
      // for correct angle display need event between before-show and show, one nextTick not enough
      await nextTick()
      await nextTick()
      const menuEl = innerMenuRef.value.parentElement
      context.emit('show')
      show(anchorEl, menuEl, attrs.value, qMenu)
    }
    const onHide = () => {
      context.emit('hide')
      hide()
    }

    const close = () => {
      internalValue.value = false
    }

    // methods for using from parents
    const updatePosition = () => {
      qMenu.value.updatePosition()
    }

    return {
      dropdownClassName,
      attrs,
      autoClose,
      qMenu,
      wrapRef,
      innerMenuRef,
      internalValue,
      beforeShow,
      onHide,
      close,
      updatePosition,
      isMinWidth,
    }
  },
})

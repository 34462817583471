import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25c6b8ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_flag = _resolveComponent("tm-flag")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_auto_size_badges = _resolveComponent("tm-auto-size-badges")!

  return (_openBlock(), _createBlock(_component_tm_auto_size_badges, {
    "items-names": _ctx.itemsNames,
    "visible-items": _ctx.visibleItems,
    "toggle-badge-size": _ctx.size,
    "toggle-badge-rounded": _ctx.rounded,
    "lines-count": _ctx.linesCount,
    class: _normalizeClass(["tm-badges", {
      'tm-badges--large-margins': _ctx.largeMargins,
    }]),
    outline: _ctx.outline,
    color: _ctx.color
  }, {
    "after-button": _withCtx(() => [
      _renderSlot(_ctx.$slots, "after-button", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createBlock(_component_tm_badge, {
          key: index,
          "is-link": !_ctx.static,
          static: _ctx.static,
          outline: _ctx.outline,
          color: _ctx.color
        }, {
          default: _withCtx(() => [
            (_ctx.showAvatar)
              ? (_openBlock(), _createBlock(_component_tm_avatar, {
                  key: 0,
                  url: item.avatar,
                  name: item.name || item,
                  color: item.color,
                  size: "xSmall",
                  class: "ml-n1 mr-2"
                }, null, 8, ["url", "name", "color"]))
              : (item.country)
                ? (_openBlock(), _createBlock(_component_tm_flag, {
                    key: 1,
                    class: "mr-1",
                    size: "xSmall",
                    country: item.country
                  }, null, 8, ["country"]))
                : (item.icon)
                  ? (_openBlock(), _createBlock(_component_tm_icon, {
                      key: 2,
                      size: _ctx.iconSize,
                      name: item.icon
                    }, null, 8, ["size", "name"]))
                  : _createCommentVNode("", true),
            _createElementVNode("span", {
              textContent: _toDisplayString(item.name || item)
            }, null, 8, _hoisted_1),
            (item.counter)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 3,
                  class: "font-weight-normal ml-1 no-truncate",
                  textContent: _toDisplayString(`(${item.counter})`)
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["is-link", "static", "outline", "color"]))
      }), 128))
    ]),
    _: 3
  }, 8, ["items-names", "visible-items", "toggle-badge-size", "toggle-badge-rounded", "lines-count", "outline", "color", "class"]))
}
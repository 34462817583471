
import { defineComponent, computed } from 'vue'
import type { HighlightChunk } from '@/services/string'
import { doHighlight } from '@/services/string'

export default defineComponent({
  name: 'Highlight',
  props: {
    cancelable: {
      type: Boolean,
    },
    text: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
  setup(props, context) {
    const chunks = computed((): HighlightChunk[] => {
      return doHighlight(props.text, props.search)
    })
    const onClick = (e: Event) => {
      if (props.cancelable) {
        e.preventDefault()
      }
      context.emit('click')
    }
    return { chunks, onClick }
  },
})

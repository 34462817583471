import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_q_chip = _resolveComponent("q-chip")!

  return (_openBlock(), _createBlock(_component_q_chip, {
    ripple: !_ctx.static,
    outline: _ctx.outline,
    class: _normalizeClass(["tm-chip", {
      [`tm-chip--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
      [`tm-chip--${_ctx.color}`]: _ctx.color,
      'tm-chip--number': _ctx.number,
      'tm-chip--tooltip': _ctx.tooltip,
      'tm-chip--static': _ctx.static,
      'tm-chip--link': _ctx.isLink,
      'tm-chip--gradient': _ctx.gradient,
      'mx-0': !_ctx.marginOn && !_ctx.marginXOn,
      'my-0': !_ctx.marginOn && !_ctx.marginYOn,
    }]),
    style: _normalizeStyle({
      background: `${_ctx.customColor} !important`,
    })
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 0,
            name: _ctx.icon,
            class: "mr-1",
            size: "small"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.close)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "remove-icon",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-close')))
          }, [
            _createVNode(_component_tm_icon, {
              name: "clear",
              size: "xSmall"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["ripple", "outline", "class", "style"]))
}
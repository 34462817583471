import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_auto_size_badges = _resolveComponent("tm-auto-size-badges")!

  return (_openBlock(), _createBlock(_component_tm_auto_size_badges, {
    ref: "tmAutoSizeRef",
    "items-names": _ctx.itemsNames,
    "visible-items": _ctx.visibleItems,
    "toggle-badge-rounded": _ctx.rounded,
    "lines-count": _ctx.linesCount,
    class: _normalizeClass(["tm-chips", {
      'tm-chips--large-margins': _ctx.largeMargins,
      'pl-2': _ctx.sidebarFieldMargins
    }]),
    chip: "",
    color: _ctx.color
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (opt) => {
        return (_openBlock(), _createBlock(_component_tm_badge, {
          key: opt[_ctx.labelValue] || opt,
          label: opt[_ctx.labelValue] || opt,
          color: _ctx.color,
          hint: opt.hint,
          icon: opt.icon,
          close: _ctx.close,
          onClickClose: ($event: any) => (_ctx.$emit('click-close', opt))
        }, null, 8, ["label", "color", "hint", "icon", "close", "onClickClose"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["items-names", "visible-items", "toggle-badge-rounded", "lines-count", "class", "color"]))
}

import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import { capitalize } from '@/services/utils'
import type { FlagCountry, ChipColor } from '@/definitions/shared/types'

export default defineComponent({
  name: 'TmBadge',
  components: {
    TmChip,
    TmFlag,
  },
  props: {
    icon: {
      type: String,
    },
    hint: {
      type: [Number, String],
    },
    country: {
      type: Object as PropType<FlagCountry>,
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String as PropType<ChipColor>,
      default: 'gray',
    },
  },
  setup() {
    return {
      capitalize,
    }
  },
})

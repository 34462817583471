import { withModifiers as _withModifiers, withKeys as _withKeys, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-efac4bca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["contenteditable", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_badge = _resolveComponent("tm-badge")!

  return (_openBlock(), _createBlock(_component_tm_badge, {
    class: _normalizeClass(["tm-badge-editable", {
      'tm-badge-editable--focused': _ctx.isFocused
    }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        ref: "editableSpan",
        contenteditable: _ctx.isFocused,
        onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSpanBlur && _ctx.onSpanBlur(...args))),
        onKeypress: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onSpanBlur && _ctx.onSpanBlur(...args)), ["prevent"]), ["enter"])),
        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onBadgeClick && _ctx.onBadgeClick(...args)), ["stop","prevent"])),
        textContent: _toDisplayString(_ctx.internalValue)
      }, null, 40, _hoisted_1)
    ]),
    _: 1
  }, 8, ["class"]))
}
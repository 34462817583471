import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, { "menu-title": _ctx.menuTitle }, {
    default: _withCtx(({ Component }) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), null, _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (index, name) => {
          return {
            name: name,
            fn: _withCtx((data) => [
              _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
            ])
          }
        })
      ]), 1024))
    ]),
    _: 3
  }, 8, ["menu-title"]))
}
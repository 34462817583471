
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'
import type { ButtonSize, ButtonColor, ButtonAlign, SizeProp } from '@/definitions/shared/types'
import kebabCase from 'lodash/kebabCase'

export default defineComponent({
  components: {
    TmSpinner,
  },
  props: {
    title: {
      type: String,
    },
    textLink: {
      type: Boolean,
    },
    iconOnly: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<ButtonSize>,
      default: '',
    },
    fullLoader: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    loadingColor: {
      type: String,
      default: 'gray',
    },
    loadingTitle: {
      type: String,
      default: 'Loading',
    },
    disable: {
      type: Boolean,
    },
    color: {
      type: String as PropType<ButtonColor>,
      default: 'default',
    },
    href: {
      type: String,
    },
    tabindex: {
      type: String,
    },
    align: {
      type: String as PropType<ButtonAlign>,
    },
    hovered: {
      type: Boolean,
    },
    clicked: {
      type: Boolean,
    },
    focused: {
      type: Boolean,
    },
    flat: {
      type: Boolean,
    },
    flatClicked: {
      type: Boolean,
    },
    noBorder: {
      type: Boolean,
    },
    rounded: {
      type: Boolean,
    },
  },
  setup(props) {
    const kebabSize = computed(() => kebabCase(props.size))

    return {
      kebabSize,
    }
  },
})


import { nextTick, watch, computed, defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import TmAutoSizeBadges from '@/components/shared/autoSize/TmAutoSizeBadges.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import type { ChipColor } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmAutoSizeBadges,
    TmBadge,
  },
  props: {
    items: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    color: {
      type: String as PropType<ChipColor>,
      default: 'gray',
    },
    visibleItems: {
      type: Number,
    },
    showAvatar: {
      type: Boolean,
    },
    rounded: {
      type: Boolean,
    },
    static: {
      type: Boolean,
    },
    linesCount: {
      type: Number,
    },
    largeMargins: {
      type: Boolean,
    },
    close: {
      type: Boolean,
    },
    sidebarFieldMargins: {
      type: Boolean,
    },
    labelValue: {
      type: String,
      default: 'label',
    },
  },
  emits: ['click-close'],
  setup(props) {
    const itemsNames = computed(() => props.items?.map(item => typeof item === 'string' ? item : (item?.label)))

    const tmAutoSizeRef = ref()
    const updateVisibleChildren = () => {
      tmAutoSizeRef.value.updateVisibleChildren()
    }

    watch(
      () => props.linesCount,
      () => {
        nextTick(() => {
          updateVisibleChildren()
        })
      }
    )

    return {
      itemsNames,
      tmAutoSizeRef,
      updateVisibleChildren,
    }
  },
})

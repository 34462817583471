
import { defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import type { SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  name: 'TmAssignee',
  components: {
    TmAvatar,
    TmButton,
    TmEllipsis,
  },
  props: {
    assignee: {
      type: Object,
    },
    overflow: {
      type: Boolean,
      default: false,
    },
    overflowMinWidth: {
      type: String,
      default: '125px',
    },
    semiBold: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as SizeProp<'' | 'small'>,
      default: '',
    },
    smallAvatar: {
      type: Boolean,
      default: false,
    },
    customSize: {
      type: String,
    },
    avatarIcon: {
      type: String,
    },
    placeholderCustomSize: {
      type: String,
    },
    emptyPlaceholder: {
      type: String,
      default: 'Unassigned',
    },
  },
})

import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-expanded"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tooltip_dropdown = _resolveComponent("tm-tooltip-dropdown")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_q_menu = _resolveComponent("q-menu")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "wrapRef",
    class: _normalizeClass(["tm-dropdown-wrap", {
      'tm-dropdown-wrap--hidden': _ctx.hideDropdownWrapper
    }]),
    "aria-expanded": _ctx.internalValue
  }, [
    (_ctx.tooltip || _ctx.$slots['tooltip-slot'])
      ? (_openBlock(), _createBlock(_component_tm_tooltip_dropdown, {
          key: 0,
          "dropdown-value": _ctx.internalValue
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "tooltip-slot", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
            ])
          ]),
          _: 3
        }, 8, ["dropdown-value"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_q_menu, _mergeProps({
      ref: "qMenu",
      "model-value": _ctx.internalValue
    }, _ctx.attrs, {
      target: _ctx.target,
      class: [
        _ctx.dropdownClassName,
        _ctx.contentClass,
        { 'filter-dropdown': _ctx.filterDropdown },
        { 'tm-dropdown--max-height-unset': _ctx.maxHeightUnset },
        { small: _ctx.size === 'small' },
      ],
      style: {
        ..._ctx.isMinWidth,
      },
      "auto-close": _ctx.autoClose,
      "transition-duration": 150,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.internalValue = _ctx.disable ? false : $event)),
      onBeforeShow: _ctx.beforeShow,
      onHide: _ctx.onHide
    }), {
      default: _withCtx(() => [
        _createElementVNode("div", {
          ref: "innerMenuRef",
          class: _normalizeClass(["tm-dropdown__main", [
          { 'tm-dropdown__main--visible-scrollbar': _ctx.visibleScrollbar },
          { 'tm-dropdown__main--overflow-hidden': _ctx.overflowHidden },
          { 'tm-dropdown__main--border-radius': _ctx.borderRadius },
        ]])
        }, [
          (_ctx.showClose)
            ? (_openBlock(), _createBlock(_component_tm_button, {
                key: 0,
                class: "tm-dropdown__close",
                size: "small",
                flat: "",
                color: "transparent",
                "icon-only": "",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_icon, {
                    name: "close",
                    size: "small"
                  })
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "menu", _normalizeProps(_guardReactiveProps({ close: _ctx.close, qMenu: _ctx.qMenu, updatePosition: _ctx.updatePosition })))
        ], 2)
      ]),
      _: 3
    }, 16, ["model-value", "target", "class", "style", "auto-close", "onBeforeShow", "onHide"])
  ], 10, _hoisted_1))
}

import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'
import type { EmojiNameType, AvatarColorVariant, SizeProp, AvatarSize, AvatarStatusVariant } from '@/definitions/shared/types'

const __default__ = defineComponent({
  name: 'TmAvatar',
  components: {
    TmEmoji,
    TmTooltip,
  },
  props: {
    emoji: {
      type: String as PropType<EmojiNameType>,
    },
    url: {
      type: String,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    outlineWidth: {
      type: String,
      default: '2px',
    },
    outlinedWithShadow: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as SizeProp<AvatarSize>,
      default: 'medium',
    },
    placeholderType: {
      type: String as PropType<'icon' | 'symbol'>,
      default: 'symbol',
    },
    placeholderCustomSize: {
      type: String,
    },
    customSize: {
      type: String,
    },
    customIcon: {
      type: String,
      default: 'person',
    },
    customIconClass: {
      type: String,
    },
    status: {
      type: String as PropType<AvatarStatusVariant>,
    },
    statusSize: {
      type: String as PropType<'' | 'small' | 'large'>,
    },
    color: {
      type: String as PropType<AvatarColorVariant>,
    },
    name: {
      type: String,
    },
    email: {
      type: String,
    },
    unread: {
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
  },
  setup(props, context) {
    type SizeObj = {
      [key: string]: string;
    }

    const sizesMap: SizeObj = {
      xxSmall: '16px',
      xSmall: '20px',
      small: '24px',
      medium: '28px',
      large: '36px',
      xLarge: '40px',
    }

    const sizesTextMap: SizeObj = {
      xxSmall: '8px',
      xSmall: '9px',
      small: '11px',
      medium: '12px',
      large: '16px',
      xLarge: '18px',
    }

    const sizesIconMap: SizeObj = {
      xxSmall: '8px',
      xSmall: '10px',
      small: '12px',
      medium: '14px',
      large: '18px',
      xLarge: '20px',
    }

    const avatarSize = computed<string>(() => {
      if (props.customSize) return props.customSize
      return sizesMap[props.size]
    })

    const avatarTextSize = computed<string>(() => props.placeholderCustomSize || sizesTextMap[props.size])

    const avatarIconSize = computed<string>(() => props.placeholderCustomSize || sizesIconMap[props.size])

    const isCorrectName = computed(() => {
      return props.name && /\w/.test(props.name[0])
    })

    const avatarSymbol = computed(() => {
      if (isCorrectName.value) {
        return props.name!.substring(0, 1).toUpperCase()
      }
      if (props.email) {
        return props.email.substring(0, 1).toUpperCase()
      }
      return ''
    })

    const hasTooltipSlot = context.slots.tooltip

    return {
      avatarSize,
      avatarTextSize,
      avatarIconSize,
      avatarSymbol,
      hasTooltipSlot,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7712bc4c": (_ctx.outlineWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__
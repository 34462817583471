
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RouterViewInheritSlots',
  props: {
    menuTitle: {
      type: String,
      default: 'Menu',
    },
  },
})


import { computed, defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { EmojiNameType, EmojiSize, SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  props: {
    size: {
      type: String as SizeProp<EmojiSize>,
      default: 'medium',
    },
    customSize: {
      type: String,
    },
    name: {
      type: String as PropType<EmojiNameType>,
      required: true,
    },
  },
  setup(props) {
    const emojiRoute = computed(() => require(`@/assets/images/emoji/${props.name}.png`))
    const sizesMap: Record<EmojiSize, string> = {
      xSmall: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      xLarge: '20px',
      xxLarge: '22px',
      xxxLarge: '24px',
    }

    const emojiSize = computed<string>(() => props.customSize ? props.customSize : sizesMap[props.size])

    return {
      emojiSize,
      emojiRoute,
    }
  },
})

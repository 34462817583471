
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import TmAutoSizeBadges from '@/components/shared/autoSize/TmAutoSizeBadges.vue'
import type { IconSize, SizeProp, ChipSize, FlagCountry, ChipColor } from '@/definitions/shared/types'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

type AssociatedBadge = {
  icon?: string;
  name: string;
  avatar?: string;
  counter?: number;
  country?: FlagCountry;
  color?: string;
}

export default defineComponent({
  components: {
    TmAutoSizeBadges,
    TmBadge,
    TmAvatar,
    TmFlag,
  },
  props: {
    items: {
      type: Array as PropType<string[] | AssociatedBadge[]>,
      default: () => [],
    },
    visibleItems: {
      type: Number,
    },
    showAvatar: {
      type: Boolean,
    },
    size: {
      type: String as PropType<ChipSize>,
    },
    rounded: {
      type: Boolean,
    },
    static: {
      type: Boolean,
    },
    linesCount: {
      type: Number,
    },
    largeMargins: {
      type: Boolean,
    },
    color: {
      type: String as PropType<ChipColor>,
      default: 'gray',
    },
    outline: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'xSmall',
    },
  },
  setup(props) {
    const itemsNames = computed(() => props.items?.map(item => typeof item === 'string' ? item : item.name))

    return {
      itemsNames,
    }
  },
})

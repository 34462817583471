
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { SizeProp, ChipSize, ChipColor } from '@/definitions/shared/types'
import kebabCase from 'lodash/kebabCase'

export default defineComponent({
  name: 'TmChip',
  props: {
    number: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: false,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as SizeProp<ChipSize>,
    },
    marginOn: {
      type: Boolean,
    },
    marginXOn: {
      type: Boolean,
    },
    marginYOn: {
      type: Boolean,
    },
    gradient: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    close: {
      type: Boolean,
    },
    color: {
      type: String as PropType<ChipColor>,
    },
    customColor: {
      type: String,
    },
  },
  emits: ['click-close'],
  setup() {
    return {
      kebabCase,
    }
  },
})

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05207cec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-dropdown-item__wrap", {
      'tm-dropdown-item__wrap--header-style': _ctx.headerStyle,
      'tm-dropdown-item__wrap--border-bottom': _ctx.borderBottom,
      'tm-dropdown-item__wrap--border-top': _ctx.borderTop,
    }])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), _mergeProps(_ctx.componentBind, {
      class: ["tm-dropdown-item", {
        'tm-dropdown-item--no-hover': _ctx.noHover,
        [`tm-dropdown-item--focused-${_ctx.focusedColor}`]: _ctx.focused,
        [`tm-dropdown-item--color-${_ctx.textColor}`]: _ctx.textColor,
        'tm-dropdown-item--cursor-default': _ctx.cursorDefault,
        'tm-dropdown-item--disable': _ctx.disable,
      }],
      onClick: _ctx.clickHandler
    }), {
      default: _withCtx(() => [
        (!_ctx.loading)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
              (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_tm_icon, {
                    key: 0,
                    name: _ctx.icon,
                    size: _ctx.iconSize,
                    mirror: _ctx.iconMirror,
                    class: _normalizeClass(["mr-2", _ctx.iconClass || (!_ctx.textColor && 'neutral--text')])
                  }, null, 8, ["name", "size", "mirror", "class"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.labelClass)
                }, _toDisplayString(_ctx.label), 3),
                (_ctx.additionalText)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(_ctx.additionalTextClass)
                    }, _toDisplayString(_ctx.additionalText), 3))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.outside)
                ? (_openBlock(), _createBlock(_component_tm_icon, {
                    key: 1,
                    name: "open_in_new",
                    size: "xLarge"
                  }))
                : _createCommentVNode("", true)
            ], true)
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_tm_skeleton, {
                width: "20px",
                height: "20px",
                type: "circle",
                class: "mr-10px"
              }),
              _createVNode(_component_tm_skeleton, {
                type: "QChip",
                height: "12px",
                "random-width": ""
              })
            ], 64))
      ]),
      _: 3
    }, 16, ["class", "onClick"]))
  ], 2))
}
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d8c0454"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-avatar-in" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_emoji = _resolveComponent("tm-emoji")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_q_avatar = _resolveComponent("q-avatar")!

  return (_openBlock(), _createBlock(_component_q_avatar, {
    size: _ctx.avatarSize,
    class: _normalizeClass(["tm-avatar", {
      'outlined': _ctx.outlined,
      'outlined-with-shadow': _ctx.outlined && _ctx.outlinedWithShadow,
      'unread': _ctx.unread,
      [_ctx.color]: _ctx.color
    }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.url)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.url,
              alt: _ctx.name
            }, null, 8, _hoisted_2))
          : _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
              (_ctx.emoji)
                ? (_openBlock(), _createBlock(_component_tm_emoji, {
                    key: 0,
                    name: _ctx.emoji,
                    size: "xLarge"
                  }, null, 8, ["name"]))
                : (_ctx.placeholderType === 'symbol' && _ctx.avatarSymbol)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: "avatar-letter",
                      style: _normalizeStyle(`font-size: ${_ctx.avatarTextSize}`)
                    }, _toDisplayString(_ctx.avatarSymbol), 5))
                  : (_openBlock(), _createBlock(_component_tm_icon, {
                      key: 2,
                      name: _ctx.customIcon,
                      "custom-size": _ctx.avatarIconSize,
                      class: _normalizeClass(_ctx.customIconClass)
                    }, null, 8, ["name", "custom-size", "class"]))
            ], true),
        (_ctx.tooltip || _ctx.hasTooltipSlot)
          ? (_openBlock(), _createBlock(_component_tm_tooltip, { key: 2 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "tooltip", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
                ], true)
              ]),
              _: 3
            }))
          : _createCommentVNode("", true),
        (_ctx.status || _ctx.$slots['status'])
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              size: "xSmall",
              class: _normalizeClass(["tm-avatar__status-icon", {
          [`tm-avatar__status-icon--${_ctx.status}`]: _ctx.status,
          [`tm-avatar__status-icon--${_ctx.statusSize}`]: _ctx.statusSize,
        }])
            }, [
              _renderSlot(_ctx.$slots, "status", {}, undefined, true)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["size", "class"]))
}

import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'TmEllipsis',
  props: {
    minWidth: {
      type: String,
      default: '200px',
    },
    ellipsisInClass: {
      type: String,
    },
    inline: {
      type: Boolean,
    },
  },
  setup(props) {
    const ellipsis = ref()
    onMounted(() => {
      ellipsis.value.style.setProperty('--tm-ellipsis-min-width', props.minWidth)
    })

    return {
      ellipsis,
    }
  },
})

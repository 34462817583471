import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/flag_other.svg'
import _imports_1 from '@/assets/images/flag_placeholder.png'


const _withScopeId = n => (_pushScopeId("data-v-5f7b60e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-flag", _ctx.size ? `tm-flag--${_ctx.kebabCase(_ctx.size)}` : ''])
  }, [
    (!_ctx.countryCode)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass(["tm-flag__img tm-flag__img--custom", _ctx.countryCode]),
          src: _imports_0,
          alt: "Country flag"
        }, null, 2))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: _normalizeClass(["tm-flag__img", _ctx.countryCode]),
          src: _imports_1,
          alt: _ctx.country.name
        }, null, 10, _hoisted_1))
  ], 2))
}
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createBlock(_component_q_icon, {
    size: _ctx.customSize || _ctx.iconSize,
    class: _normalizeClass(["tm-icon", {
      [`tm-icon--rotate${_ctx.rotate}`]: _ctx.rotate,
      'tm-icon--mirror': _ctx.mirror,
      'tm-icon--bold': _ctx.bold
    }]),
    style: _normalizeStyle({
      'color': _ctx.customColor
    })
  }, null, 8, ["size", "class", "style"]))
}
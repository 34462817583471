import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d769c84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_spinner = _resolveComponent("tm-spinner")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    "no-caps": "",
    unelevated: "",
    class: _normalizeClass(["tm-btn", {
      [`tm-btn--${_ctx.kebabSize}`]: _ctx.size,
      [`tm-btn--${_ctx.color}`]: _ctx.color,
      'tm-btn--text-link': _ctx.textLink,
      'tm-btn--icon-only': _ctx.iconOnly,
      'tm-btn--flat-clicked': _ctx.flatClicked,
      'tm-btn--no-border': _ctx.noBorder,
      'tm-btn--rounded': _ctx.rounded,
      'tm-btn--flat': _ctx.flat,
      'tm-btn--hovered': _ctx.hovered,
      'tm-btn--clicked': _ctx.clicked,
      'tm-btn--focused': _ctx.focused,
    }]),
    ripple: false,
    outline: false,
    title: _ctx.title,
    disable: _ctx.disable || _ctx.loading,
    href: _ctx.href,
    tabindex: _ctx.tabindex,
    align: _ctx.align
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["tm-btn__loading", { 'tm-btn--text-link': _ctx.textLink }])
          }, [
            _renderSlot(_ctx.$slots, "loader", {}, () => [
              _createVNode(_component_tm_spinner, {
                class: _normalizeClass([{ 'mr-2': !_ctx.fullLoader && !_ctx.iconOnly }, { 'align-self-center': _ctx.textLink }]),
                color: _ctx.loadingColor
              }, null, 8, ["class", "color"])
            ], true),
            (!_ctx.fullLoader)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.loadingTitle), 1))
              : _createCommentVNode("", true)
          ], 2))
        : _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ], true)
    ]),
    _: 3
  }, 8, ["title", "class", "disable", "href", "tabindex", "align"]))
}
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "truncate" }
const _hoisted_2 = {
  key: 0,
  class: "ml-1 body-text-regular-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_flag = _resolveComponent("tm-flag")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_chip = _resolveComponent("tm-chip")!

  return (_openBlock(), _createBlock(_component_tm_chip, {
    class: "tm-badge",
    outline: "",
    size: "large",
    color: _ctx.color
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.country)
          ? (_openBlock(), _createBlock(_component_tm_flag, {
              key: 0,
              size: "xSmall",
              class: "mr-1",
              country: _ctx.country
            }, null, 8, ["country"]))
          : _createCommentVNode("", true),
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_tm_icon, {
              key: 1,
              size: "small",
              class: "mr-1",
              name: _ctx.icon
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.capitalize(`${_ctx.label}`)), 1),
          (_ctx.hint)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.hint), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["color"]))
}

import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import { kebabCase } from 'lodash'
import type { SizeProp, FlagSize, FlagCountry } from '@/definitions/shared/types'

export default defineComponent({
  name: 'TmFlag',
  props: {
    country: {
      type: Object as PropType<FlagCountry>,
      required: true,
    },
    size: {
      type: String as SizeProp<FlagSize>,
      default: '',
    },
  },
  setup(props) {
    const countryCode = computed(() => props.country.id?.toLowerCase())
    return { countryCode, kebabCase }
  },
})

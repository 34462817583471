
import { defineComponent, ref, nextTick, watch } from 'vue'
import TmBadge from '@/components/shared/TmBadge.vue'

export default defineComponent({
  components: {
    TmBadge,
  },
  props: {
    modelValue: {
      type: String,
    },
    forbiddenSymbols: {
      type: RegExp,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const editableSpan = ref()
    const isFocused = ref(false)
    const internalValue = ref(props.modelValue)

    const onBadgeClick = async () => {
      isFocused.value = true
      await nextTick()
      if (editableSpan.value) {
        editableSpan.value.focus()
      }
    }

    const onSpanBlur = (e: any) => {
      isFocused.value = false
      internalValue.value = e.srcElement.textContent
      const newValue = e.srcElement.textContent.replace(props.forbiddenSymbols, '')
      nextTick(() => {
        internalValue.value = newValue
      })
      context.emit('update:modelValue', newValue)
    }

    watch(() => props.modelValue, (newValue) => {
      internalValue.value = newValue
    })

    return {
      internalValue,
      editableSpan,
      isFocused,
      onBadgeClick,
      onSpanBlur,
    }
  },
})

import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-298646a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-emoji" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.emojiRoute,
      style: _normalizeStyle({
        'width': _ctx.emojiSize,
        'height': _ctx.emojiSize,
      })
    }, null, 12, _hoisted_2)
  ]))
}
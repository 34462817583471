
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import type { SizeProp, IconSize, IconRotate } from '@/definitions/shared/types'

export default defineComponent({
  name: 'TmIcon',
  props: {
    size: {
      type: String as SizeProp<IconSize>,
      default: 'medium',
    },
    rotate: {
      type: Number as PropType<IconRotate>,
    },
    mirror: {
      type: Boolean,
    },
    bold: {
      type: Boolean,
    },
    customSize: {
      type: String,
      default: '',
    },
    customColor: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const sizesMap: Record<IconSize, string> = {
      xSmall: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      xLarge: '20px',
      xxLarge: '22px',
      xxxLarge: '24px',
    }

    const iconSize = computed<string>(() => {
      return sizesMap[props.size]
    })

    return {
      iconSize,
    }
  },
})

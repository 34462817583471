import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0abfba4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-country" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "tm-country-text"
}
const _hoisted_4 = ["title"]
const _hoisted_5 = {
  key: 1,
  class: "distinct--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_flag = _resolveComponent("tm-flag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.customFlag)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.customFlag,
          class: "tm-country__custom-image",
          alt: "flag"
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createBlock(_component_tm_flag, {
          key: 1,
          class: "tm-country__flag",
          country: _ctx.country,
          size: _ctx.size
        }, null, 8, ["country", "size"])),
    (!_ctx.hideTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "title", { country: _ctx.country }, () => [
            (!_ctx.hideTitle)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "tm-country-title",
                  title: _ctx.showTooltip ? _ctx.country.name : ''
                }, _toDisplayString(_ctx.country.name), 9, _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.country.counter)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " (" + _toDisplayString(_ctx.country.counter) + ") ", 1))
              : _createCommentVNode("", true)
          ], true)
        ]))
      : _createCommentVNode("", true)
  ]))
}